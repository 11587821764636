import { baseEnvironmentProd } from 'src/environments/baseEnvironment.prod';

export const environment = {
  ...baseEnvironmentProd,
  organizationPrefix: 'MER',
  organizationUUID: '88811bb2-020a-4b4d-b400-13efabff1ace',
  activeLanguage: 'it-IT',
  tableStructureUUID: '141ffb4a-620c-493c-a5a2-3ae7783ba3a0',
  multipleAdminCommunity: true,
  requiredField: {},
  systemToken: '',
  htmlFields: [
    ['bold', 'italic', 'underline', 'strike'],
    [{ header: 1 }, { header: 2 }],
    ['blockquote'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }, 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { align: [] }],
    ['clean'],

  ],

  basicConfiguration: {
    htmlEditorMaxLength: {
      active: false,
      where: [
        {
          name: 'product',
          value: 270,
        },
      ],
    },
  },
  googleAPIKey: '',
  manageTemplates: false,
  manageUserFilter: false,

  productEditorTabIcons: ['r', 'z', 'M', 'x', 'm'],
  pwaThemeColor: 'rgba(0, 72, 205, 1.0)',
};
